import axios from "@/plugins/Axios";

const model = "tipos";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(state) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, state);
}

function update(id, state) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, state);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

export { all, find, create, update, remove };
