<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateTipo">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Tipo:</label>
      <input v-model="tipo.name" class="form-control" id="name" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/tipos";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      tipo: {
        name: "",
      },
      tipoId: this.$route.params.id,
    };
  },
  mounted() {
    this.getTipo();
  },
  methods: {
    getTipo() {
      if (!this.tipoId) {
        return;
      }
      find(this.tipoId).then((response) => {
        this.tipo = response.data;
      });
    },
    saveOrUpdateTipo() {
      if (this.tipoId) {
        this.updateTipo();
      } else {
        this.saveTipo();
      }
    },
    saveTipo() {
      create(this.tipo).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/publicaciones-tipos");
      });
    },
    updateTipo() {
      update(this.tipoId, this.tipo).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/publicaciones-tipos");
      });
    },
  },
};
</script>
